
import { CBadge } from "@coreui/react";
import paletaCor from "./paletaCor";

const RenderColorVisto = ({ id, nome, color }) => {
    switch (id) {
        case 1:
            return <CBadge style={{ backgroundColor: paletaCor.Trabalho }}>{nome}</CBadge>;
            break;
        case 2:
            return <CBadge style={{ backgroundColor: paletaCor.Turismo }}>{nome}</CBadge>;
            break;
        case 3:
            return <CBadge style={{ backgroundColor: paletaCor.Residência }}>{nome}</CBadge>;
            break;
        case 4:
            return <CBadge style={{ backgroundColor: paletaCor.Negócios }}>{nome}</CBadge>;
            break;
        case 5:
            return <CBadge style={{ backgroundColor: paletaCor.Residência }}  >{nome}</CBadge>;
            break;

        default:
            return <CBadge bgcolor={color}>{nome}</CBadge>;
            break; 
    }
};

export default RenderColorVisto