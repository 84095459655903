import { FileCopy, Folder, Person } from '@mui/icons-material';
import { Box, Card, CardContent, Grid, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { AppButtonRoot } from 'app/components/AppBuutonRoot';
import Ficheiros from 'app/utils/Ficheiros';
import RenderColorVisto from 'app/utils/renderColorVisto';
import { ArcElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from 'chart.js';
import { useEffect, useState } from 'react';
import { StatusBadge } from '../Clientes/function';
import { Cliente } from '../Clientes/util';
import Processo from '../processo/util';
import { Projecto } from '../projecto/util';
import { Usuario } from '../usuario/util';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement);

const Dashboard = () => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const lineChartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'Monthly Earnings',
        data: [10, 30, 50, 70, 20, 40],
        fill: true,
        backgroundColor: 'rgba(63, 81, 181, 0.2)',
        borderColor: '#3f51b5',
      },
    ],
  };

  const pieChartData = {
    labels: ['Pendente', 'Cancelado', 'andamento'],
    datasets: [
      {
        data: [1542, 6451, 84574],
        backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726'],
      },
    ],
  };

  const [dashboardData, setDashboardData] = useState({
    totalProcesso: 0,
    totalCliente: 0,
    totalProjecto: 0,
    totalUsuario: 0,
    totalVisto: 0,
    totalDocumentos: 0,
    processosMensais: [],
    processosDiarios: [],
    processosHoje: [],
    processosRecentes: []
  });

  async function fetchData() {
    const cliente = new Cliente();
    const projecto = new Projecto();
    const usuario = new Usuario();
    const processo = new Processo();

    const ficheiros = new Ficheiros();

    try {
      const [
        totalCliente,
        totalProjecto,
        totalUsuario,
        totalVisto,
        totalProcesso,
        totalDocumentos,
        processosMensais,
        processosDiarios,
        processosHoje,
        processosRecentes
      ] = await Promise.all([
        cliente.contar(),
        projecto.contar(),
        usuario.contar(),
        processo.contar(), // Assuming 'visto' was a typo and should be 'processo'
        processo.contar(),
        ficheiros.contar(),
        processo.listarEstatisticaMensal(),
        processo.listarEstatisticaDiaria(),
        processo.listarEstatisticaHoje(),
        processo.buscarProcessosRecentes({ limit: 12 })
      ]);

      setDashboardData((prev) => ({
        ...prev,
        totalCliente,
        totalProjecto,
        totalUsuario,
        totalVisto,
        totalProcesso,
        totalDocumentos,
        processosMensais,
        processosDiarios,
        processosHoje,
        processosRecentes
      }));
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []); 

  return (
    <AppButtonRoot>
      <Box sx={{ flexGrow: 1, padding: 3 }}>
        <Grid container spacing={3}>
          {/* Top Metrics */}
          {[
            { title: 'Processos', value: dashboardData.totalProcesso || 0, icon: <FileCopy />, color: '#42A5F5' },
            { title: 'Projectos', value: dashboardData.totalProjecto || 0, icon: <Folder />, color: '#FF7043' },
            { title: 'Usuarios', value: dashboardData.totalUsuario-1 || 0, icon: <Person />, color: '#66BB6A' },
            { title: 'Clientes', value: dashboardData.totalCliente || 0, icon: <Person />, color: '#66BB6A' },

          ].map((metric, index) => (
            <Grid item xs={12} md={3} key={index}>
              <Card sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                <Box sx={{ marginRight: 2, color: metric.color }}>{metric.icon}</Box>
                <CardContent>
                  <Typography variant="h6">{metric.title}</Typography>
                  <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{metric.value}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}

          {/* Charts */}
          <Grid item xs={12} md={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">Processos recentes</Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Nome</TableCell>
                      <TableCell>Passaporte</TableCell>
                      <TableCell>Cliente</TableCell>
                      <TableCell>Projeto</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Categoria</TableCell>
                      <TableCell>Visto</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dashboardData?.processosRecentes?.length > 0 ? (
                      dashboardData?.processosRecentes?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => (
                          <TableRow key={row?.id}>
                            <TableCell>{row?.numero}</TableCell>
                            <TableCell>{row?.nome}</TableCell>
                            <TableCell>{row?.passaporteNumero}</TableCell>
                            <TableCell>{row?.cliente?.nome}</TableCell>
                            <TableCell>
                              {RenderColorVisto({
                                id: row?.projecto?.id,
                                nome: row?.projecto?.nome,
                              })}
                            </TableCell>
                            <TableCell>{StatusBadge({ status: row?.status?.id })}</TableCell>
                            <TableCell>{row?.tipo?.nome}</TableCell>
                            <TableCell>{RenderColorVisto({
                              id: row?.tipoVistoId,
                              nome: row?.tipoVisto?.nome,
                            })}</TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          NÃO ENCONTRADO
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={dashboardData?.processosRecentes?.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </CardContent>
            </Card>
          </Grid>

          {/* <Grid item xs={12} md={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">Processos</Typography>
                <Pie data={pieChartData} />
              </CardContent>
            </Card>
          </Grid> */}
        </Grid>
      </Box>
    </AppButtonRoot>
  );
};

export default Dashboard;
